.app {
  background: #d9d6d6;
  height: 100vh;
  display: flex;
  justify-content: center;
  
}

.form{
  margin-top: 50px;
  width: 40%;
  height: max-content;
  border-radius: 10px;
  background: white;
  padding: 20px;

  
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}
.support_text{
  font-family: "Roboto",sans-serif;
  font-size: 24px;
}
.form_title{
  margin-top: 10px;
  font-family: "Roboto",sans-serif;
  font-size: 20px;
}

.body{
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  font-family: "Inter",sans-serif;
  font-size: 12px;
}

.body input{
  height: 30px;
  padding: 5px;
  font-family: "Roboto",sans-serif;
  font-size: 10px;
  margin: 5px 0 20px 0;
  width: 50%;
}

.body textarea{
  height: 70px;
  padding: 5px;
  font-family: "Roboto",sans-serif;
  font-size: 10px;
  margin: 5px 0 20px 0;
  width: 50%;
}

.button_send{
  background-color: rgb(99, 99, 212);
  border-radius: 4px;
  padding: 10px 30px;
  color: white;
  font-family: "Roboto",sans-serif;
  cursor: pointer;
}

.modal{
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #703a3a3e;
  transition: all 0.2s ease;
}